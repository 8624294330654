/* eslint-disable no-underscore-dangle */
import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import classNames from "classnames";
import { v4 as uuidv4 } from "uuid";
import Slick from "react-slick";
import { Switch } from "@material-ui/core";
import {
  addToCart,
  minusCartItem,
  addGiftCount,
  minusGiftCount,
  deleteGifts,
} from "../../redux/actions/cart";
import Button from "../Button/Button";
import Modal from "../Modal/Modal";
import RecommendedItem from "../RecommendedItem/RecommendedItem";
import noImage from "../../assets/img/no_image.jpg";

import "./Product.scss";
import ProductPosition from "../ProductPosition/ProductPosition";


const AWS_URL = "https://xlfood-imgs.s3.eu-north-1.amazonaws.com/";

const Product = ({
  id,
  name,
  taste,
  weight,
  composition,
  price,
  size,
  image,
  extra,
  gift,
}) => {

  const dispatch = useDispatch();
  const [addedCount, setAddedCount] = useState(0);
  const cartItems = useSelector(({ cart }) => cart.items);
  const [showExtraModal, setShowExtraModal] = useState(false);
  const [showGiftModal, setShowGiftModal] = useState(false);
  const allProducts = useSelector(({ products }) => products.items);
  const [extraProducts, setExtraProducts] = useState([]);
  const [giftProduct, setGiftProduct] = useState({});
  const giftCount = useSelector(({ cart }) => cart.giftCount);
  const { items: optionsItems } = useSelector(
    ({ options }) => options
  ) || [];

  const { items: sizesItems } = useSelector(
    ({sizes }) => sizes
  );





  const [chooseOption, setChooseOption] = useState(false);





  // if(name === "Креветки по-тайски") {
  //   console.log(taste)
  // }

  const handleGiftCount = (number) => {
    if (number > 0) dispatch(addGiftCount(number));
    if (number < 0) dispatch(minusGiftCount(-number));
  };

  const cartSliderRef = useRef(null);
  const giftSliderRef = useRef(null);

  const handleMinusClick = () => {
    setAddedCount((prev) => (prev >= 1 ? prev - 1 : 0));
    dispatch(minusCartItem(id));

    if (gift !== null && gift.product.length) {
      handleGiftCount(-gift.count);
      dispatch(deleteGifts({ prodId: id, count: gift.count }));
    }
  };

  const handlePlusClick = () => {
    setAddedCount((prev) => prev + 1);

    const tasteBasket = chooseOption ? optionsItems.find((item) => item._id === taste).name : "";
    const priceEnd = chooseOption ? parseInt(optionsItems.find((item) => item._id === taste).price, 10) + parseInt(price, 10) : price
    dispatch(addToCart({ id, name, image, price: priceEnd, taste: tasteBasket }));
    if (
      Object.keys(cartItems).find((item) => item === id) === undefined &&
      extraProducts.length
    ) {
      setShowExtraModal(true);
    }

    if (gift !== null && gift.product.length) {
      setShowGiftModal(true);
      handleGiftCount(gift.count);
    }
  };

  const handleExtraModal = () => setShowExtraModal(false);
  const handleGiftModal = () => setShowGiftModal(false);

  useEffect(() => {
    if (cartItems[id]) {
      setAddedCount(cartItems[id].count);
    }
  }, []);

  useEffect(() => {
    if (extra !== null) {
      setExtraProducts(
        extra.map((item) => allProducts.find((prod) => prod._id === item))
      );
    }
  }, []);

  useEffect(() => {
    if (gift !== null && gift.product.length) {
      setGiftProduct({
        count: gift.count,
        gifts: gift.product.map((item) =>
          allProducts.find((prod) => prod._id === item)
        ),
      });
    }
  }, []);

  const settings = {
    arrows: false,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    swipe: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const giftSliderSettings = {
    arrows: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    swipe: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const next = () => {
    if (cartSliderRef.current !== null) {
      cartSliderRef.current.slickNext();
    }
  };
  const prev = () => {
    if (cartSliderRef.current !== null) {
      cartSliderRef.current.slickPrev();
    }
  };


  let sizesProduct = [];
  let sizesProducts = [];

  if(size != null) {
     sizesProduct = Object.entries(size).map((t,k) =>(t))

    sizesProducts = sizesProduct.map((item) => {

        if(Array.isArray(item[1])) {
          if(item[1].length > 0) {
            return item[0];
          }
      }


       return ''
    }).filter((item) => item !== '')


  }



  return (
    <>
      <li
        className={classNames("product", { "product--added": addedCount > 0 })}
      >
        <div className="product__description">
          <div className="product__top">
            <span className="product__name">{name}</span>
            {weight && <span className="product__weight">{weight} гр</span>}
            {composition && (
              <p className="product__compound">
                <span>Состав:</span> {composition}
              </p>
            )}
          </div>

          <div className="product__top">


                {optionsItems.length > 0 && optionsItems.find((item) => item._id === taste) && (
                <>

                 <p className="product__compound">

                <div>
                <span className="product__weight">Опции</span>
                  {optionsItems.find((item) => item._id === taste).name}
                  <Switch  onChange={() => setChooseOption(!chooseOption)}/>
                  +{optionsItems.find((item) => item._id === taste).price && optionsItems.find((item) => item._id === taste).price} руб
                </div>
                </p>
                </>
                )}


          </div>





          <div className="product__bottom">
            {sizesProducts.length > 0 ? (
             <>

             {

              sizesProducts.map((item1) => (



                sizesItems.find((item3) => item3.name_eng === item1) !==  undefined &&    (
                  <ProductPosition
                  taste={chooseOption && optionsItems.find((item2) => item2._id === taste).name}
                  nameProduct={name}
                  size={sizesItems.length> 0 ? sizesItems.find((item3) => item3.name_eng === item1) !== undefined && sizesItems.find((item3) => item3.name_eng === item1).name_rus : ''}
                  price= {chooseOption ? parseInt(optionsItems.find((item) => item._id === taste).price, 10) + parseInt(size[item1][0].price, 10) : size[item1][0].price }
                  weight={size[item1][0].weight}
                  id={size[item1][0]._id}
                  image={image}
                  gift={gift}
                  extraProducts={extraProducts}
                  setShowExtraModal={setShowExtraModal}
                  setShowGiftModal={setShowGiftModal}
                />
                )





              ))
             }



           </>) : (
              <>
              <b className="product__price">
                {chooseOption ? parseInt(optionsItems.find((item) => item._id === taste).price, 10) + parseInt(price.toLocaleString("ru-RU"), 10) : price.toLocaleString("ru-RU") } ₽
              </b>
              <div className="product__counter">
                {addedCount > 0 && (
                  <>
                    <Button
                      style={{
                        color: "#fff",
                        border: "1px solid #fff",
                        borderRadius: "8px",
                        backgroundColor: "#ffba00",
                      }}
                      hover={{
                        border: "1px solid black",
                        color: "black",
                      }}
                      onClick={handleMinusClick}
                    >
                      -
                    </Button>
                    <b className="product__count">{addedCount}</b>
                  </>
                )}
                <Button
                  style={{
                    color: "#fff",
                    border: "1px solid #fff",
                    borderRadius: "8px",
                    backgroundColor: "#ffba00",
                  }}
                  hover={{
                    border: "1px solid black",
                    color: "black",
                  }}
                  onClick={handlePlusClick}
                >
                  +
                </Button>
              </div>
            </>
           )
          }
          </div>
        </div>
        <div className="product__photo">
          <img src={`${image}`} alt={name} />
        </div>
      </li>
      <Modal
        withButton={false}
        active={showExtraModal}
        closeHandler={handleExtraModal}
      >
        {extraProducts.length ? (
          <>
            <h3>К данному товару вам может подойти</h3>
            <div className="cart-slider">
              <Button
                style={{
                  color: "#FFF",
                  borderRadius: "8px",
                  backgroundColor: "rgba(167, 167, 167, 0.19)",
                }}
                hover={{
                  color: "#FFF",
                  backgroundColor: "rgba(233, 215, 53, 0.2)",
                }}
                onClick={prev}
              >
                {"<"}
              </Button>
              <Slick {...settings} ref={cartSliderRef}>
                {extraProducts.map((item) => (
                  item ? <RecommendedItem
                    key={uuidv4()}
                    id={item._id}
                    name={item.name}
                    price={item.cost}
                    image={item.imageUrl}
                    showCount
                  >
                    {item.name}
                  </RecommendedItem> : ''

                ))}
              </Slick>
              <Button
                style={{
                  color: "#fff",
                  borderRadius: "8px",
                  backgroundColor: "rgba(167, 167, 167, 0.19)",
                }}
                hover={{
                  color: "#E9D735",
                  backgroundColor: "rgba(233, 215, 53, 0.2)",
                }}
                onClick={next}
              >
                {">"}
              </Button>
            </div>
          </>
        ) : null}
      </Modal>
      <Modal
        withButton={false}
        active={showGiftModal}
        closeHandler={handleGiftModal}
        closeText="Сохранить"
        giftModal
      >
        {giftProduct && Object.keys(giftProduct).length ? (
          <div className="cart-slider">
            <h3>Предлагаем вам подарок</h3>
            <p>Вам осталось выбрать {giftCount}</p>
            <div className="gift">
              <Slick {...giftSliderSettings} ref={giftSliderRef}>
                {giftProduct.gifts.map((item) => (

                  item ? <RecommendedItem
                    key={uuidv4()}
                    id={item._id}
                    name={item.name}
                    price={0}
                    gift
                    showCount
                    giftObj={{
                      prodId: id,
                      giftId: item._id,
                    }}
                  >
                    {item.name}
                  </RecommendedItem> : ''

                ))}
              </Slick>
            </div>
          </div>
        ) : null}
      </Modal>
    </>
  );
};

Product.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  taste: PropTypes.string.isRequired,
  weight: PropTypes.number,
  composition: PropTypes.string,
  price: PropTypes.number.isRequired,
  image: PropTypes.string,
  size: PropTypes.bool,
  extra: PropTypes.arrayOf(PropTypes.string),
  gift: PropTypes.bool,
};

Product.defaultProps = {
  weight: null,
  composition: null,
  image: null,
  extra: null,
  size: null,
  gift: null,
};

export default Product;

import { useState, useEffect } from 'react'
import { Switch, Route, BrowserRouter } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import fetchCategories from './redux/actions/categories'
import fetchProducts from './redux/actions/products'
import fetchShowreel from './redux/actions/showreel'
import Header from './components/Header/Header'
import Footer from './components/Footer/Footer'
import Modal from './components/Modal/Modal'
import Home from './pages/Home'
import Cart from './pages/cart/Cart'
import Success from './pages/cart/Success'
import fetchOptions from './redux/actions/options'
import fetchSizes from './redux/actions/sizes'


const App = () => {
  const [isWorking, setIsWorking] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchCategories())
    dispatch(fetchProducts())
    dispatch(fetchShowreel())
    dispatch(fetchOptions())
    dispatch(fetchSizes())
  }, [])

  useEffect(() => {
    // с 18:30 до 03:00 по UTC (с 1:30 до 10:00 по крск)
    const date = new Date();
    const utcHour = date.getUTCHours()
    const utcMinutes = date.getUTCMinutes()
    if ((utcHour >= 18 && utcMinutes >= 30) || (utcHour >= 18 || utcHour < 3)) {
      setIsWorking(true)
    } else if (utcHour === 3) {
     setIsWorking(false)
    }
  }, [])

  return (
    <div className="App" style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
      <h1>Проводятся технические работы</h1>
      {/* <BrowserRouter>
        <Header />
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/cart" exact component={Cart} />
          <Route path="/success" exact component={Success} />
        </Switch>
        <Footer />
        <Modal
          withButton={false}
          active={isWorking}
          closeText="Понятно!"
        >
          К сожалению, мы пока что не можем обработать ваш заказ :(
          <br />
          Оставьте заявку на сайте, мы перезвоним как начнем работу, с 10:00!
        </Modal>
      </BrowserRouter> */}
    </div>
  );
}

export default App

import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactDom from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import { Transition } from "react-transition-group";
import PropTypes from "prop-types";
import DeliveryAddress from "./ModalBody/DeliveryAddress";
import { deleteGiftCount } from "../../redux/actions/cart";
import Button from "../Button/Button";
import "./Modal.scss";

const Modal = React.memo(
  ({
    Btn,
    btnText,
    btnImage,
    btnStyle,
    childrenDelivery,
    children,
    containerClass,
    withButton,
    active,
    closeHandler,
    closeText,
    giftModal,
  }) => {
    const dispatch = useDispatch();

    const giftCount = useSelector(({ cart }) => cart.giftCount);

    const modalRef = useRef();
    const [open, setOpen] = useState(active);


    const handleClose = () => {

      setOpen(false);
      if (closeHandler) closeHandler();
      if (giftModal) dispatch(deleteGiftCount());
    };
    const handleOpen = () => setOpen(true);

    const handleOutsideClick = useCallback(
      (e) => {
        const path = e.path || (e.composedPath && e.composedPath());
        if (path[0] === modalRef.current) {
          handleClose();
        }
      },
      [open]
    );

    useEffect(() => {
      if (open) {
        document.addEventListener("click", handleOutsideClick);
        document.body.style.paddingRight = `${
          window.innerWidth - document.body.scrollWidth
        }px`;
        document.body.style.overflow = "hidden";
      } else {
        document.removeEventListener("click", handleOutsideClick);
        document.body.style.overflow = "";
        document.body.style.paddingRight = "";
      }
    }, [open]);

    useEffect(() => {
      setOpen(active);
    }, [active]);

    return (
      <>
        {withButton &&
          (<Button style={btnStyle} onClick={handleOpen}>
            {btnImage || btnText}
          </Button>)
          }
        {ReactDom.createPortal(
          <Transition
            nodeRef={modalRef}
            in={open}
            timeout={500}
            mountOnEnter
            unmountOnExit
          >
            {(state) => (
              <div className="modal" ref={modalRef}>
                <div
                  className={`modal__container ${
                    containerClass || "modal__container--default"
                  } ${state}`}
                >
                  <Button className="modal__close"
                   onClick={handleClose}>
                    <svg width="26" height="25" viewBox="0 0 26 25"
                        fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M6.11768 19.1176L19.8824 5.88235" stroke="#18181B"
                      strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                      <path d="M19.8824 19.1176L6.11768 5.88235" stroke="#18181B"
                      strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                      </svg>
                  </Button>
                  {childrenDelivery &&<DeliveryAddress handleClose={handleClose } /> }

                  {children}
                  {/* {!giftModal && (
                    <Button
                      style={{
                        color: "#a7a7a7",
                        padding: "0.5em 1em",
                        border: "1px solid #a7a7a7",
                      }}
                      hover={{
                        color: "black",
                        border: "1px solid black",
                      }}
                      onClick={handleClose}
                    >
                      {closeText !== null ? closeText : "Закрыть"}
                    </Button>
                  )} */}
                  {giftModal && giftCount !== 0 && (
                    <Button
                      style={{
                        color: "#a7a7a7",
                        padding: "0.5em 1em",
                        border: "1px solid #a7a7a7",
                      }}
                      hover={{
                        color: "black",
                        border: "1px solid black",
                      }}
                      onClick={handleClose}
                    >
                      {closeText !== null ? closeText : "Закрыть"}
                    </Button>
                  )}
                  {giftModal && giftCount === 0 && (
                    <Button
                      style={{
                        color: "#ffba00",
                        padding: "0.5em 1em",
                        border: "1px solid #ffba00",
                      }}
                      hover={{
                        color: "#ffba00",
                        border: "1px solid #ffba00",
                      }}
                      onClick={handleClose}
                    >
                      {closeText !== null ? closeText : "Закрыть"}
                    </Button>
                  )}
                </div>
              </div>
            )}
          </Transition>,
          document.getElementById("portal")
        )}
      </>
    );
  }
);

Modal.propTypes = {
  active: PropTypes.bool,
  withButton: PropTypes.bool,
  btnText: PropTypes.string,
  btnImage: PropTypes.node,
  Btn: PropTypes.node,
  btnStyle: PropTypes.objectOf(PropTypes.string),
  childrenDelivery: PropTypes.string,
  children: PropTypes.node,
  containerClass: PropTypes.string,
  closeHandler: PropTypes.func,
  closeText: PropTypes.string,
  giftModal: PropTypes.bool,
};

Modal.defaultProps = {
  active: false,
  withButton: true,
  btnText: "Открыть",
  Btn: null,
  btnImage: null,
  btnStyle: {},
  childrenDelivery: false,
  children: null,
  containerClass: null,
  closeHandler: null,
  closeText: null,
  giftModal: false,
};

export default Modal;

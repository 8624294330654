import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import classnames from "classnames";
import {
  addToCart,
  minusCartItem,
  addGiftCount,
  minusGiftCount,
  addGift,
  removeGift,
} from "../../redux/actions/cart";
import Button from "../Button/Button";
import noImage from "../../assets/img/no_image.jpg";

import "./RecommendedItem.scss";

const RecommendedItem = ({
  id,
  name,
  image,
  price,
  showCount,
  gift,
  giftObj,
}) => {
  const [addedCount, setAddedCount] = useState(0);
  const giftCount = useSelector(({ cart }) => cart.giftCount);

  const dispatch = useDispatch();

  const handlePlusClick = () => {
    dispatch(addToCart({ id, name, image, price }));
    if (gift) {
      setAddedCount((prev) => prev + 1);
      dispatch(minusGiftCount(1));
      dispatch(addGift(giftObj));
    } else if (showCount) setAddedCount((prev) => prev + 1);
  };

  const handleMinusClick = () => {
    dispatch(minusCartItem(id));
    if (gift) {
      setAddedCount((prev) => prev - 1);
      dispatch(addGiftCount(1));
      dispatch(removeGift(giftObj));
    } else if (showCount) setAddedCount((prev) => prev - 1);
  };

  return (
    <div
      className={classnames("cart-slider__slide recommended-item", {
        gift,
      })}
    >
      {image && (
        <img className="recommended-item__image" src={image} alt={name} />
      ) }
      <div>
        <span className="recommended-item__name">{name}</span>
        <div className="recommended-item__bottom">
          <b className="recommended-item__price">
            {price.toLocaleString("ru-RU")} ₽
          </b>
          <div className="product__counter">
            {showCount && addedCount > 0 && (
              <Button
                style={{
                  minWidth: "25px",
                  minHeight: "25px",
                  color: "#fff",
                  border: "1px solid rgba(255, 186, 0, 0.6)",
                  borderRadius: "8px",
                  backgroundColor: "rgba(255, 186, 0, 0.6)",
                }}
                hover={{
                  boxShadow: "0 0 5px rgba(255, 186, 0, 0.6)",
                }}
                onClick={handleMinusClick}
              >
                -
              </Button>
            )}
            {showCount ? <p>{addedCount}</p> : null}
            {!gift && (
              <Button
                style={{
                  minWidth: "25px",
                  minHeight: "25px",
                  color: "#fff",
                  border: "1px solid rgba(255, 186, 0, 0.6)",
                  borderRadius: "8px",
                  backgroundColor: "rgba(255, 186, 0, 0.6)",
                }}
                hover={{
                  boxShadow: "0 0 5px rgba(255, 186, 0, 0.6)",
                }}
                onClick={handlePlusClick}
              >
                +
              </Button>
            )}
            {gift && giftCount > 0 && (
              <Button
                style={{
                  minWidth: "25px",
                  minHeight: "25px",
                  color: "#fff",
                  border: "1px solid rgba(255, 186, 0, 0.6)",
                  borderRadius: "8px",
                  backgroundColor: "rgba(255, 186, 0, 0.6)",
                }}
                hover={{
                  boxShadow: "0 0 5px rgba(255, 186, 0, 0.6)",
                }}
                onClick={handlePlusClick}
              >
                +
              </Button>
            )}
            {/* {gift && addedCount === 0 && (
              <Button
                style={{
                  minWidth: "25px",
                  minHeight: "25px",
                  color: "#fff",
                  border: "1px solid rgba(255, 186, 0, 0.6)",
                  borderRadius: "8px",
                  backgroundColor: "rgba(255, 186, 0, 0.6)",
                }}
                hover={{
                  boxShadow: "0 0 5px rgba(255, 186, 0, 0.6)",
                }}
                onClick={handlePlusClick}
              >
                Добавить
              </Button>
            )}
            {gift && addedCount > 0 && (
              <Button
                style={{
                  minWidth: "25px",
                  minHeight: "25px",
                  color: "#fff",
                  border: "1px solid rgba(255, 186, 0, 0.6)",
                  borderRadius: "8px",
                  backgroundColor: "rgba(255, 186, 0, 0.6)",
                }}
                hover={{
                  boxShadow: "0 0 5px rgba(255, 186, 0, 0.6)",
                }}
                onClick={handleMinusClick}
              >
                Убрать
              </Button>
            )} */}
          </div>
        </div>
      </div>
    </div>
  );
};

RecommendedItem.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  image: PropTypes.string,
  showCount: PropTypes.bool,
  gift: PropTypes.bool,
  giftObj: PropTypes.bool,
};

RecommendedItem.defaultProps = {
  image: null,
  showCount: false,
  gift: false,
  giftObj: null,
};

export default RecommendedItem;

const optionsLoaded = payload => ({
  type: 'OPTIONS_LOADED',
  payload,
})

const setOptions = payload => ({
  type: 'SET_OPTIONS',
  payload,
})

const fetchOptions = () => async dispatch => {
  try {
    dispatch(optionsLoaded(false))

    const items = await fetch('/api/options').then(response =>
      response.json(),
    )

    dispatch(setOptions(items))
  } catch (error) {
    throw new Error(error)
  }
}

export default fetchOptions

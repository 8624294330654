import { createTheme } from '@material-ui/core/styles'

const theme = createTheme({
  overrides: {
    MuiInput: {
      root: {
	color: '#FFF !important',
        '&$focused': {
          color: '#ffba00 !important',
        },
        '@media (max-width:600px)': {
          color: '#FFFF00 !important',
        },
      },
      underline: {
	'&::before': {
        borderbottom: '1px solid #FFF',},
        '&::after': {
          borderBottom: '2px solid #ffba00',
          color: '#00FF00 !important',
        },
      },
    },
    MuiSelect: {
      root: {
        '@media (max-width:600px)': {
          fontSize: '0.8rem !important',
        },
      },
    },
MuiFormHelperText: {
      root: {        
          color: '#FFF !important',
      },
    },
MuiTypography: {
      root: {
          color: '#FFF !important',
      },
    },
    MuiFormLabel: {
      root: {
        '@media (max-width:600px)': {
          fontSize: '0.8rem !important',
          color: '#FFF !important',
        },
      },
    },
    MuiInputLabel: {
      root: {
	color: '#FFF',
        '&$focused': {
          color: '#FFF !important',
        },
      },
    },
  },
})

export default theme

/* eslint-disable no-unused-expressions */
import produce from "immer";

const initialState = {
  items: {},
  totalPrice: 0,
  totalPriceWithDiscount: 0,
  totalCount: 0,
  promoCodeIsValid: false,
  promoCodeLoading: false,
  isPickup: false,
  gifts: {},
  giftCount: 0,
};

const PROMOCODE_DISCOUNT = 0.1;
const PICKUP_DISCOUNT = 0.15;

const getTotalPrice = (items) =>
  Object.keys(items).reduce((sum, key) => items[key].totalPrice + sum, 0);

const getTotalCount = (items) =>
  Object.keys(items).reduce((sum, key) => items[key].count + sum, 0);

const getTotalPriceWithDiscount = (totalPrice, state = initialState) => {
  let DISCOUNT = 0;

  if (state.promoCodeIsValid) DISCOUNT += PROMOCODE_DISCOUNT;
  if (state.isPickup) DISCOUNT += PICKUP_DISCOUNT;

  return totalPrice * (1 - DISCOUNT);
};

const cart = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_TO_CART": {
      const { id, name, image, price, taste, count = 1, type='' } = action.payload;

      return produce(state, (draft) => {
        if (!state.items[id]) {
          if(type === 'changeActualPrice') {

            draft.items[id] = { name, image, price, totalPrice: price*count, count, taste };

          } else {
            draft.items[id] = { name, image, price, totalPrice: price, count: 1, taste };

          }

        } else {
          draft.items[id].count = state.items[id].count + 1;
          draft.items[id].totalPrice =
            // state.items[id].totalPrice + state.items[id].price;
            state.items[id].totalPrice + price;
        }



        draft.totalPrice = getTotalPrice(draft.items);
        draft.totalPriceWithDiscount = getTotalPriceWithDiscount(
          draft.totalPrice,
          draft
        );
        draft.totalCount = getTotalCount(draft.items);

        // Yandex ecommerce
        window.dataLayer.push({
          ecommerce: {
            currencyCode: "RUB",
            add: {
              products: [
                {
                  id,
                  name,
                  price,
                  quantity: 1,
                },
              ],
            },
          },
        });
      });
    }
    case "MINUS_CART_ITEM": {
      const id = action.payload;

      return produce(state, (draft) => {
        const { name } = draft.items[id];

        // Yandex ecommerce
        window.dataLayer.push({
          ecommerce: {
            currencyCode: "RUB",
            remove: {
              products: [
                {
                  id,
                  name,
                  quantity: 1,
                },
              ],
            },
          },
        });

        if (state.items[id].count > 1) {
          draft.items[id].count = state.items[id].count - 1;
          if (draft.items[id].totalPrice !== 0) {
            draft.items[id].totalPrice =
              state.items[id].totalPrice - state.items[id].price;
          }
        } else {
          delete draft.items[id];
        }
        draft.totalPrice = getTotalPrice(draft.items);
        draft.totalPriceWithDiscount = getTotalPriceWithDiscount(
          draft.totalPrice,
          draft
        );
        draft.totalCount = getTotalCount(draft.items);
      });
    }
    case "REMOVE_CART_ITEM": {
      const id = action.payload;

      return produce(state, (draft) => {
        const { name, count } = draft.items[id];

        // Yandex ecommerce
        window.dataLayer.push({
          ecommerce: {
            currencyCode: "RUB",
            remove: {
              products: [
                {
                  id,
                  name,
                  quantity: count,
                },
              ],
            },
          },
        });

        delete draft.items[id];
        draft.totalPrice = getTotalPrice(draft.items);
        draft.totalPriceWithDiscount = getTotalPriceWithDiscount(
          draft.totalPrice,
          draft
        );
        draft.totalCount = getTotalCount(draft.items);
      });
    }
    case "CLEAR_CART": {
      return produce(state, (draft) => {
        draft.items = {};
        draft.totalPrice = 0;
        draft.totalPriceWithDiscount = 0;
        draft.totalCount = 0;
        delete draft.promoCodeIsValid;
      });
    }
    case "ACTIVATE_PROMOCODE": {
      return produce(state, (draft) => {
        draft.promoCodeIsValid = action.payload;
        draft.totalPriceWithDiscount = getTotalPriceWithDiscount(
          draft.totalPrice,
          draft
        );
      });
    }
    case "PROMOCODE_LOADING": {
      return produce(state, (draft) => {
        draft.promoCodeLoading = action.payload;
      });
    }
    case "SET_TYPE_PICKUP": {
      return produce(state, (draft) => {
        draft.isPickup = action.payload;
        draft.totalPriceWithDiscount = getTotalPriceWithDiscount(
          draft.totalPrice,
          draft
        );
      });
    }
    case "ADD_GIFT_COUNT": {
      return produce(state, (draft) => {
        draft.giftCount += action.payload;
      });
    }
    case "MINUS_GIFT_COUNT": {
      return produce(state, (draft) => {
        if (state.giftCount !== 0 && state.giftCount - action.payload >= 0) {
          draft.giftCount -= action.payload;
        }
      });
    }
    case "DELETE_GIFT_COUNT": {
      return produce(state, (draft) => {
        draft.giftCount = 0;
      });
    }
    case "ADD_GIFT": {
      return produce(state, (draft) => {
        const { prodId, giftId } = action.payload;
        if (!state.gifts[prodId]) {
          draft.gifts[prodId] = [giftId];
        } else {
          draft.gifts[prodId].push(giftId);
        }
      });
    }
    case "REMOVE_GIFT": {
      return produce(state, (draft) => {
        const { prodId, giftId } = action.payload;
        const index = state.gifts[prodId].lastIndexOf(giftId);
        if (index > -1) draft.gifts[prodId].splice(index, 1);
      });
    }
    case "DELETE_GIFTS": {
      return produce(state, (draft) => {
        const { prodId, count } = action.payload;
        if (draft.gifts[prodId] && draft.gifts[prodId].length) {
          const length = draft.gifts[prodId].length;
          if (length === count || count > length) {
            for (let i = length - 1; i > -1; i--) {
              const giftId = draft.gifts[prodId][i];
              if (draft.items[giftId] && draft.items[giftId].count > 1) {
                // Yandex ecommerce
                window.dataLayer.push({
                  ecommerce: {
                    currencyCode: "RUB",
                    remove: {
                      products: [
                        {
                          id: giftId,
                          quantity: 1,
                        },
                      ],
                    },
                  },
                });

                draft.items[giftId].count -= 1;
              } else if (
                draft.items[giftId] &&
                draft.items[giftId].count === 1
              ) {
                // Yandex ecommerce
                window.dataLayer.push({
                  ecommerce: {
                    currencyCode: "RUB",
                    remove: {
                      products: [
                        {
                          id: giftId,
                          quantity: 1,
                        },
                      ],
                    },
                  },
                });

                delete draft.items[giftId];
              }
              draft.gifts[prodId].splice(i, 1);
            }
            if (draft.gifts[prodId]) {
              delete draft.gifts[prodId];
            }
          } else if (length > count) {
            for (let i = length - 1; i > length - count - 1; i--) {
              const giftId = draft.gifts[prodId][i];
              if (draft.items[giftId] && draft.items[giftId].count > 1) {
                // Yandex ecommerce
                window.dataLayer.push({
                  ecommerce: {
                    currencyCode: "RUB",
                    remove: {
                      products: [
                        {
                          id: giftId,
                          quantity: 1,
                        },
                      ],
                    },
                  },
                });

                draft.items[giftId].count -= 1;
              } else if (
                draft.items[giftId] &&
                draft.items[giftId].count === 1
              ) {
                // Yandex ecommerce
                window.dataLayer.push({
                  ecommerce: {
                    currencyCode: "RUB",
                    remove: {
                      products: [
                        {
                          id: giftId,
                          quantity: 1,
                        },
                      ],
                    },
                  },
                });

                delete draft.items[giftId];
              }
              draft.gifts[prodId].splice(i, 1);
            }
          }
        }
        draft.totalCount = getTotalCount(draft.items);
      });
    }
    default:
      return state;
  }
};

export default cart;

const sizesLoaded = payload => ({
  type: 'SIZES_LOADED',
  payload,
})

const setSizes = payload => ({
  type: 'SET_SIZES',
  payload,
})

const fetchSizes = () => async dispatch => {
  try {
    dispatch(sizesLoaded(false))

    const items = await fetch('/api/sizes').then(response =>

      response.json(),
    )

    dispatch(setSizes(items))
  } catch (error) {
    throw new Error(error)
  }
}

export default fetchSizes
